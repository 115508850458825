.blog-wrapper{
    // padding-bottom: 3px;
    position: relative;
    z-index: 1;
    .blog-details{
        margin-top: 30px;        
        @include media-breakpoint-down(lg){
            margin-top: 20px;
            p{
                margin-bottom: 8px;
            }
        }
    }    
}
.blog-title{
    line-height: 32px;    
    --#{$prefix}link-color-rgb: var(--#{$prefix}body-color-rgb);
    --#{$prefix}link-color: var(--#{$prefix}body-color);
    --#{$prefix}link-hover-color: var(--#{$prefix}body-color);
    --#{$prefix}link-hover-color-rgb: var(--#{$prefix}body-color-rgb);
    a{
        padding-bottom: 4px;        
    }
}

.section-blog-single{
    padding-top: 100px;
    @include media-breakpoint-down(md){    
        padding-top: 50px;
    }
}
.blog-single-text{
    --#{$prefix}heading-color: #F9FFFC;
    --#{$prefix}blog-text-bottom-spacing: 70px;
    --#{$prefix}blog-text-max-width: 1300px;
    --#{$prefix}contact-list-margin-top: 50px;

    max-width: var(--#{$prefix}blog-text-max-width);
    bottom: var(--#{$prefix}blog-text-bottom-spacing);
    .contact-lists{
        margin-top: var(--#{$prefix}contact-list-margin-top);
        --#{$prefix}body-color: #E4EAE7;
        li{
            font-weight: 400;
            color: var(--#{$prefix}body-color);
        }
    }
    @include media-breakpoint-down(xxl){
        --#{$prefix}blog-text-bottom-spacing: 40px;
        --#{$prefix}blog-text-max-width: 1100px;
        --#{$prefix}contact-list-margin-top: 30px;
        .display-3{
            font-size: 70px;
        }
    }
    @include media-breakpoint-down(xl){
        --#{$prefix}blog-text-max-width: 900px;
        .display-3{
            font-size: 50px;
        }
    }
    @include media-breakpoint-down(lg){
        --#{$prefix}blog-text-bottom-spacing: 30px;
        --#{$prefix}blog-text-max-width: 700px;
        --#{$prefix}contact-list-margin-top: 20px;
        .display-3{
            font-size: 40px;
        }
    }
    @include media-breakpoint-down(md){
        --#{$prefix}blog-text-bottom-spacing: 20px;
        --#{$prefix}blog-text-max-width: 550px;
        --#{$prefix}contact-list-margin-top: 16px;
        .display-3{
            font-size: 35px;
        }
    }
    @include media-breakpoint-down(sm){
        --#{$prefix}blog-text-bottom-spacing: 14px;
        --#{$prefix}blog-text-max-width: 400px;
        --#{$prefix}contact-list-margin-top: 8px;
        .display-3{
            font-size: 20px;
        }
        .contact-lists {
            --#{$prefix}contact-lists-gap: 18px;
            li{
                font-size: 14px;
                &::after{
                    height: 14px;
                }
            }
            
        }
    }
}

.wp-block-tag-cloud {
    gap: 12px;
    .tag-cloud-link{
        border: 1px solid var(--#{$prefix}border-color);
        padding: 8px;
        transition: all .2s ease-in-out;  
        overflow: hidden;      
    }    
}

.wp-block-quote{
    padding: 80px 0 60px 0;
    @include media-breakpoint-down(md){
        padding: 20px 0 30px 0;
    }
}

.wp-block-heading{
    font-weight: 400;
    font-size: 18px;
}

.post-title{
    max-width: 399px;
}



.post-heading h5{
    @include media-breakpoint-down(xxl){    
        font-size: 20px;
    }
}

.post-image{
    max-width: 162px;
    max-height: 95px;
    min-width: 162px;
    min-height: 95px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    img{
        max-width: 100%;
        height: auto;
    }
}

.category-lists a{
    font-size: 20px;
    &.active{
        background-size: 100% 2px !important;


        --#{$prefix}link-hover-animation-color: var(--#{$prefix}primary);
        background: linear-gradient(to right, var(--#{$prefix}link-hover-animation-color) 0%, var(--#{$prefix}link-hover-animation-color) 100%);
        background-position-x: 0%;
        background-position-y: 0%;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: left 100%;
        transition-duration: 0.5s;
        --#{$prefix}btn-border-radius: 0 !important;
    }
}

.author-image-wrapper{
    img{
        width: 80px;
        height: 80px;
        object-fit: cover;
        @include media-breakpoint-down(md){    
            width: 50px;
            height: 50px;
        }
    }    
}

.post-wrapper, .blog-inner, .section-comment-inner{
    --#{$prefix}border-color: var(--#{$prefix}secondary);
}

.post-wrapper{
    position: relative;    
    &::after{
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background: var(--#{$prefix}primary);
        left: calc(50% - 1px);
        top: 0;
        @include media-breakpoint-down(xl){    
            left: calc(50% - 12px);
        }
        @include media-breakpoint-down(lg){    
            content: none;
        }
    }
    @include media-breakpoint-down(lg){
        border-bottom: 1px solid var(--#{$prefix}border-color);
        border-top: 1px solid var(--#{$prefix}border-color);
        padding: 30px 0;
    }
}

.blog-inner{
    border-bottom: 1px solid var(--#{$prefix}border-color);
    padding-bottom: 24px;
    @include media-breakpoint-down(lg){
        border-bottom: none;
        padding-bottom: 0;
    }
}
.section-comment-inner{
    border-top: 1px solid var(--#{$prefix}border-color);
    @include media-breakpoint-down(lg){
        border-top: none;
        h5{
            font-size: 19px;
        }
    }
}